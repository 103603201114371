<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-23 15:34:11
 * @LastEditTime: 2021-04-14 14:21:17
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="sljr-render">
    <Breadcrumb></Breadcrumb>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
</style>